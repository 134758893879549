import { Modal } from "../ui/components/Modal";

export class PaymentPayAnyWay extends Modal {
    initialized = false;
    type = 'payanyway';

    init() {
        super.init();

        if (this.initialized) {
            return;
        }

        this.initialized = true;
    }

    show(paymentData) {
        if (!paymentData) {
            return
        }

        let widgetUri = "/assistant.htm?"
            + "MNT_ID=" + paymentData.accountId
            + "&MNT_AMOUNT=" + paymentData.amount
            + "&MNT_CURRENCY_CODE=" + paymentData.currency
            + "&MNT_TRANSACTION_ID=" + paymentData.invoice
            + "&MNT_DESCRIPTION=" + paymentData.description
            + "&MNT_SUBSCRIBER_ID=" + paymentData.subscriberId
            + "&MNT_SUCCESS_URL=" + paymentData.links.complete
            + "&MNT_FAIL_URL=" + paymentData.links.fail
            // + "&MNT_TEST_MODE=" + this.paymentData.isTest
            + "&moneta.locale=ru&version=v3";

        let url = paymentData.url + widgetUri;

        document.location.href = url;
    }

    onPaymentCancel(invoiceId) {

    }

    /** Общие события виджета */
    onSuccess(response, paymentData) {

    }

    onCancel() {

    }

    onError(errors) {
    }
}
