import UIComponent from "../component";

export class Button extends UIComponent {
    init() {
        this.spinner = this.element.querySelector('.spinner-border');
        this.searchBtn = this.element.querySelector('.search-btn');
    }

    disable() {
        this.element.setAttribute('disabled', true);
        if (this.spinner) {
            this.spinner.classList.remove('d-none');
        }

        if (this.searchBtn) {
            this.searchBtn.classList.add('d-none')
        }
    }

    enable() {
        this.element.removeAttribute('disabled');

        if (this.spinner) {
            this.spinner.classList.add('d-none');
        }

        if (this.searchBtn) {
            this.searchBtn.classList.remove('d-none')
        }
    }
}
