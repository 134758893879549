import goals from "../utils/goals";
import UIComponent from "../ui/component";
import FilesApi from "../api/files";


export class UploadCheckout extends UIComponent {

    init() {
        this.includeComponents({
            'paymentSelectModal': 'document@#paymentSelectModal',
            'errorBag': 'document@.js-upload-form-error',
        });

        this.transcodeBtn?.on('click', this.onTranscodeBtnClick.bind(this));
        this.payBtn?.on('click', this.onPayBtnClick.bind(this));
        this.profitable?.payProfitableBtn?.on('click', this.onProfitablePayBtnClick.bind(this));
        this.transcribeUrl = this.attr('data-url');

        // console.log(this);
    }

    onTranscodeBtnClick(e) {
        e.preventDefault();

        goals.reach('transcode');

        this.transcodeBtn.disable();

        FilesApi.transcribe(
            this.parent.getFiles(),
        ).then((response) => {
            this.transcodeBtn.enable();
        }).catch((errors) => {
            this.transcodeBtn.enable();
            this.errorBag.show(errors)
        })
    }

    onPayBtnClick(e) {
        e.preventDefault();
        let formData = this.parent.getFiles();
        formData.addToQueue = 1;

        this.paymentSelectModal.show(this, this.transcribeUrl, formData);
        this.payBtn.disable();
        this.profitable.payProfitableBtn?.disable();
    }

    onProfitablePayBtnClick(e) {
        e.preventDefault();
        let formData = this.parent.getFiles();
        formData.profitable = this.offeredProfitable;
        formData.addToQueue = 1;

        this.paymentSelectModal.show(this, this.transcribeUrl, formData);
        this.payBtn.disable();
        this.profitable.payProfitableBtn?.disable();
    }

    onInvoiceReceived(response) {
        goals.reach('payForBatch');
        return true;
    }

    onPaymentSuccess(response, paymentData) {
        console.log("checkout success", response, paymentData);
        if (paymentData.links.account != null) {
            setInterval(() => {
                window.location.href = paymentData.links.account;
            }, 1000)
        } else if(paymentData.links.files) {
            setInterval(() => {
                window.location.href = paymentData.links.files;
            }, 5000)
        }
    }

    onPaymentCancel() {
        this.transcodeBtn.enable();
        this.payBtn.enable();
        this.errorBag.show('payment.cancelled');
    }

    onPaymentError(error) {
        this.transcodeBtn.enable();
        this.payBtn.enable();
        console.log(this);
        this.errorBag.show(error);
    }

    // Обновление формы с суммой платежа
    update(files) {
        // console.log("FILES", files, $('.js-upload-promo-form').length);
        // Если промо форма

        this.preloader.show();
        this.payBtn.hide();
        this.transcodeBtn.hide();
        this.totalPanel.hide();

        this.profitable.hide();

        // Отправляем запрос
        FilesApi.calculate(
            files,
        ).then((response) => {

            goals.reach('calculate');

            // Показываем результат
            this.totalPanel.total_duration = response.totalDuration;
            this.totalPanel.available = response.available;

            if (response.paymentRequired > 0) {
                // console.log(this.payBtn, response.paymentRequired);
                this.payBtn.amount = response.paymentRequired;
                this.payBtn.show();
                this.transcodeBtn.hide();

                // Блок выгодного предложения
                if (response.profitable && this.profitable) {
                    this.profitable.update(response.profitable);
                    this.offeredProfitable = response.profitable.id;
                    this.profitable.show();
                }

            } else {
                this.payBtn.hide();
                this.transcodeBtn.show();
            }

            this.preloader.hide();
            this.totalPanel.show();
        }).catch((errors) => {
            this.preloader.hide();
            this.totalPanel.show();
            this.errorBag.show(errors);
        })
    }
}
