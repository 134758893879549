import UIComponent from "../component";
import goals from '../../utils/goals';

export class GTrigger extends UIComponent {
    init() {
        setTimeout(() => {
            if (this.status === 'success') {
                goals.reach('paymentSuccess')
            } else if (this.status === 'cancelled') {
                goals.reach('paymentCancelled')
            } else if (this.status === 'failed') {
                goals.reach('paymentCancelled')
            }
        }, 2000)
    }


}
